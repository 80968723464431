import { SALES_COMPONENT_BASE_FRAGMENT } from '@/graphql/_Fragments/SalesComponent/Base';
import {
  SALES_PACKAGE_COMPONENT_CONFIG_BASE_FRAGMENT,
} from '@/graphql/_Fragments/SalesPackageComponentConfig/Base';

export const SALES_PACKAGE_COMPONENT_WITH_CONFIG_FRAGMENT = `
  fragment salesPackageComponentWithConfigFragment on SalesPackageComponent {
    uid
    salesPackageComponentConfigs {
      ...salesPackageComponentConfigBaseFragment
    }
    salesComponent {
      ...salesComponentBaseFragment
    }
  }
  ${SALES_PACKAGE_COMPONENT_CONFIG_BASE_FRAGMENT}
  ${SALES_COMPONENT_BASE_FRAGMENT}
`;
